function capitalizeFirstLetterOfEachWord(str: string, truncate = false) {
	const capitalizedLetters = str
		.split(" ")
		.map((word) => capitalizeFirstLetter(word, truncate));

	return capitalizedLetters.join("");
}

export function capitalizeFirstLetter(str: string, truncate = false): string {
	if (str.length > 0) {
		return str.charAt(0).toUpperCase() + (truncate ? "" : str.slice(1));
	}
	return "";
}

export function logoify(name: string): string {
	if (name.length > 4) {
		return capitalizeFirstLetterOfEachWord(name, true).substring(0, 4);
	}
	return name.toUpperCase();
}

export const normalize = (str: string): string =>
	str
		.normalize("NFD")
		.replace(/\p{Diacritic}/giu, "")
		.toLowerCase();
